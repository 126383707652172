import React, { FunctionComponent } from "react";
import { PageLayout } from "../components/Layout";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";
import { Stack, Typography } from "@mui/material";
import Image from "next/image";
import warningIcon from "../public/images/Warning.svg";

const NotFound: FunctionComponent = () => {
  const { t } = useTranslation("common");

  return (
    <PageLayout>
      <Stack alignItems="center">
        <Image src={warningIcon} alt="" />
      </Stack>
      <Typography variant="h1">{t("404.title")}</Typography>
      <Typography>{t("404.content")}</Typography>
    </PageLayout>
  );
};

export default NotFound;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(locale && (await serverSideTranslations(locale, ["common"]))),
    },
  };
};
