import { Card, Container, Grid } from "@mui/material";
import { FunctionComponent, PropsWithChildren } from "react";
import { Footer } from "./Footer";

export const PageLayout: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <Container maxWidth="sm" style={{ paddingLeft: "19px", paddingRight: "19px" }}>
        <Grid container>
          <Grid item xs={12} sx={{ margin: "20px 0 20px 0" }}>
            <Card style={{ padding: "40px" }}>{children}</Card>
          </Grid>
        </Grid>
        <Footer />
      </Container>
    </>
  );
};
